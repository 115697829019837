import React, { Fragment } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./index.scss";
import Loader from "./layouts/Loader/Loader";

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

const Landingpageapp = React.lazy(() => import("./components/Landingpageapp"));
const LandingDocumentSummary = React.lazy(() => import("./components/LandingPage/LandingDocumentSummary"));

const AuthLogin = React.lazy(() => import("./Authentication/Login"));
const AuthForgot = React.lazy(() => import("./Authentication/Forgot"));
const AuthSignup = React.lazy(() => import("./Authentication/Signup"));

const App = React.lazy(() => import("./components/app"));
const ContractHub = React.lazy(() => import("./components/ContractHub/ContractHub"));
const Contact = React.lazy(() => import("./components/Contact/Contact"));
const Privacy = React.lazy(() => import("./components/Privacy/Privacy"));
const UserDashboard = React.lazy(() => import("./components/Dashboard/UserDashboard"));
const AdminDashboard = React.lazy(() => import("./components/Dashboard/AdminDashboard"));
const AdminCompanies = React.lazy(() => import("./components/Companies/AdminCompanies"));
const UserCompanies = React.lazy(() => import("./components/Companies/UserCompanies"));
const Addcompany = React.lazy(() => import("./components/Companies/Addcompany"));
const Editcompany = React.lazy(() => import("./components/Companies/Editcompany"));
const AdminExecutives = React.lazy(() => import("./components/Executives/AdminExecutives"));
const UserExecutives = React.lazy(() => import("./components/Executives/UserExecutives"));
const Addexecutive = React.lazy(() => import("./components/Executives/Addexecutive"));
const Editexecutive = React.lazy(() => import("./components/Executives/Editexecutive"));
const UserContracts = React.lazy(() => import("./components/Contracts/UserContracts"));
const AdminContracts = React.lazy(() => import("./components/Contracts/AdminContracts"));
const Addcontract = React.lazy(() => import("./components/Contracts/Addcontract"));
const ContractSummary = React.lazy(() => import("./components/Contracts/ContractSummary"));
const Editcontract = React.lazy(() => import("./components/Contracts/Editcontract"));
const Subscriptions = React.lazy(() => import("./components/Subscriptions/Subscriptions"));
const Subscribe = React.lazy(() => import("./components/Subscriptions/Subscribe"));
const Activeusers = React.lazy(() => import("./components/Activeusers/Activeusers"));
const Activesubscribers = React.lazy(() => import("./components/Activesubscribers/Activesubscribers"));
const Notifications = React.lazy(() => import("./components/Notifications/Notifications"));
const Profile = React.lazy(() => import("./components/Profile/Profile"));
const Support = React.lazy(() => import("./components/Support/Support"));
const UserSettings = React.lazy(() => import("./components/Settings/UserSettings"));
const AdminSettings = React.lazy(() => import("./components/Settings/AdminSettings"));

const MailInbox = React.lazy(() => import("./components/MiactInbox/MailInbox"));
const MailView = React.lazy(() => import("./components/MiactInbox/MailView"));
const NegotiateNewOffer = React.lazy(() => import("./components/Negotiation/NegotiateNewOffer"));
const NegotiateExistingRole = React.lazy(() => import("./components/Negotiation/NegotiateExistingRole"));

const Root = () => {
  return (
    <Fragment>
      <BrowserRouter>
        <React.Suspense fallback={<Loader />}>
          <Routes>
          <Route index path={`${process.env.PUBLIC_URL}/`} element={<Landingpageapp />} />
            <Route path={`${process.env.PUBLIC_URL}/document-summary`} element={<LandingDocumentSummary />} />
            <Route
              path={`${process.env.PUBLIC_URL}/contract-hub`}
              element={<ContractHub />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/contact`}
              element={<Contact />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/privacy`}
              element={<Privacy />}
            />
            <Route element={<AuthLogin />} />
            <Route
              path={`${process.env.PUBLIC_URL}/login`}
              element={<AuthLogin />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/forgot`}
              element={<AuthForgot />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/register`}
              element={<AuthSignup />}
            />
            <Route path={`${process.env.PUBLIC_URL}/`}
              element={<App />}>
              <Route>
                <Route
                  path={`${process.env.PUBLIC_URL}/user-dashboard`}
                  element={<UserDashboard />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/admin-dashboard`}
                  element={<AdminDashboard />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/admin-companies`}
                  element={<AdminCompanies />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/user-companies`}
                  element={<UserCompanies />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/addcompany`}
                  element={<Addcompany />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/editcompany`}
                  element={<Editcompany />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/admin-executives`}
                  element={<AdminExecutives />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/user-executives`}
                  element={<UserExecutives />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/addexecutive`}
                  element={<Addexecutive />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/editexecutive`}
                  element={<Editexecutive />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/user-contracts`}
                  element={<UserContracts />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/admin-contracts`}
                  element={<AdminContracts />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/addcontract`}
                  element={<Addcontract />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/contractsummary`}
                  element={<ContractSummary />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/editcontract`}
                  element={<Editcontract />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/subscriptions`}
                  element={<Subscriptions />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/subscribe`}
                  element={<Subscribe />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/active-users`}
                  element={<Activeusers />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/active-subscribers`}
                  element={<Activesubscribers />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/notifications`}
                  element={<Notifications />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/user-inbox`}
                  element={<MailInbox />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/user-mail-view`}
                  element={<MailView />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/negotiate-new-offer`}
                  element={<NegotiateNewOffer />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/negotiate-existing-role`}
                  element={<NegotiateExistingRole />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/profile`}
                  element={<Profile />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/support`}
                  element={<Support />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/user-settings`}
                  element={<UserSettings />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/admin-settings`}
                  element={<AdminSettings />}
                />
              </Route>
            </Route>
          </Routes>
        </React.Suspense>
      </BrowserRouter>
    </Fragment>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Root />);

/*fetch(process.env.REACT_APP_PAYMENTS_API_BASE_URL + '/config')
.then((response) => response.json())
.then((data) => {
  const stripePromise = loadStripe(data.publishableKey);
  ReactDOM.render(
    <React.StrictMode>
      <Elements stripe={stripePromise}>
        <App />
      </Elements>
    </React.StrictMode>,
    document.getElementById('root')
  );
})
.catch((error) => {
  console.error('Error:', error);
});*/

/*fetch(process.env.REACT_APP_PAYMENTS_API_BASE_URL + '/config')
.then((response) => response.json())
.then((data) => {
  const stripePromise = loadStripe(data.publishableKey);
  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(
    <Root>
      <React.StrictMode>
        <Elements stripe={stripePromise}>
          <App />
        </Elements>
      </React.StrictMode>
    </Root>
  );
}).catch((error) => {
  console.error('Error:', error);
});*/